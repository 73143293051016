import './App.css';
import AppRoutes from "./AppRoutes";
import {store} from "./core/store";
import {Provider} from "react-redux";
import {SettingsConsumer, SettingsProvider} from "./layout/context/settingsContext";
import ThemeComponent from "./layout/theme/ThemeComponent";
import { ToastContainer } from "react-toastify";
import {HelmetProvider} from "react-helmet-async";
function App() {
  return (
    <div className="App">
        <Provider store={store}>
          <HelmetProvider>
            <SettingsProvider>
                <SettingsConsumer>
                    {({settings}) => {
                        return <ThemeComponent settings={settings}>
                  <AppRoutes />
                          <ToastContainer />
                        </ThemeComponent>
                    }}
                </SettingsConsumer>
            </SettingsProvider>
          </HelmetProvider>
        </Provider>
    </div>
  );
}

export default App;
